import * as yup from 'yup';
import { REGEXP_USERNAME, REGEXP_USERNAME_SPECIAL_CHAR } from '@app/constants/validation';
import { publicNameSchema } from '@src/shared/lib/validation';
const checkStringEdges = (str) => {
    const firstCharCheck = REGEXP_USERNAME_SPECIAL_CHAR.test(str[0]);
    const lastCharCheck = REGEXP_USERNAME_SPECIAL_CHAR.test(str[str.length - 1]);
    return firstCharCheck === false && lastCharCheck === false;
};
const contactsObjectSchema = (translateFn) => (socials) => {
    const result = {};
    Object.keys(socials).forEach((socialsKey) => {
        result[socialsKey] = yup.object().shape({
            value: yup.string().min(1, translateFn('profile.messages.socials_empty')).max(128, translateFn('profile.messages.socials_too_long')),
        });
    });
    return yup.object().shape(result);
};
const validationSchema = (translateFn) => yup.object().shape({
    displayName: yup.lazy(publicNameSchema(translateFn)),
    username: yup.string().required(translateFn('profile.messages.nickname_empty'))
        .matches(REGEXP_USERNAME, translateFn('profile.messages.nickname_only_latin_and_numbers'))
        .max(30, '')
        .test('special-chars-in-edge', translateFn('profile.messages.nickname_special_chars_edges'), checkStringEdges),
    about: yup.string().max(250, ''),
    contacts: yup.lazy(contactsObjectSchema(translateFn)),
});
export default validationSchema;
